<template>
  <b-container fluid>
    <StrapiEditoComponent></StrapiEditoComponent>
    <StrapiCollectionComponent :collection="collection" :codeSociete="societe.code">
    </StrapiCollectionComponent>
  </b-container>
</template>

<script>
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import StrapiCollectionComponent from "../../components/strapi/StrapiCollectionComponent.vue";
export default {
  name: "StrapiFaqView",
  components: {
    StrapiEditoComponent,
    StrapiCollectionComponent,
  },
  computed: {
    collection() {
      return this.$store.getters["strapi/collection"]("questions");
    },
    societe() {
      // FIXME Pour l'instant, une seule FAQ pour les gouverner toutes
      return this.$store.state.union;
      //return this.$store.getters["societe"];
    },
  },
};
</script>
